<template>
    <el-dialog title="详情" :visible.sync="detail.visible" @open="open"
        destroy-on-close center append-to-body width="1000px">
        <x-detail label-width="140px" :entity="entity">
            <x-detail-item prop="kaHao" label="卡号" />
            <x-detail-item prop="xingMing" label="姓名" />
            <x-detail-item prop="shouJiHao" label="手机号" />
            <x-detail-item prop="kaNeiYuE" label="卡内余额" />
            <x-detail-item prop="chongZhiYuE" label="充值余额" />
            <x-detail-item prop="zengSongYuE" label="赠送余额" />
            <x-detail-item prop="xiaoKaSJ" label="销卡时间" />
            <x-detail-item prop="caoZuoRen" label="操作人" />
        </x-detail>
    </el-dialog>
</template>

<script>
    import {getDetail} from "@/service/hygl/XiaoKaJiLu";
    import XDetailBase from "@/components/x/XDetailBase";

    export default {
        mixins: [XDetailBase],
        beforeCreate() {
            this.defaultModel = {
                kaHao: "", // 卡号
                xingMing: "", // 姓名
                shouJiHao: "", // 手机号
                kaNeiYuE: "", // 卡内余额
                chongZhiYuE: "", // 充值余额
                zengSongYuE: "", // 赠送余额
                xiaoKaSJ: "", // 销卡时间
                caoZuoRen: "", // 操作人
            };
            this.getDetailService = getDetail;
        }
    }
</script>

<style scoped>

</style>